/**
 * Calculates the percentage of a part with respect to a whole.
 *
 * @param part - The part you want to find the percentage of.
 * @param whole - The whole or total value.
 * @param round - boolean indicating if the value should be rounded to the nearest whole number.
 *
 * @returns The calculated percentage.
 */
export function calculatePercentage(part: number, whole: number, round: boolean = false): number {
    const valuePercentage = (part / whole) * 100;
    return round ? Math.round(valuePercentage) : valuePercentage;
}
